import { NovatiqAuthorizationClient } from '@/_auth-client';
import { ROUTES } from '@/_contants';
import { AuthorizationService } from '@/_services';
import { LoginModel } from '@/_types';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationHelper {
  constructor(
    private readonly authorizationClient: NovatiqAuthorizationClient,
    private readonly authorizationService: AuthorizationService,
    private readonly router: Router
  ) {}

  logIn(data: LoginModel, noRedirect = false) {
    return this.authorizationService.logIn(data).pipe(
      tap((response) => {
        this.authorizationClient.addUserSession(response);
        /**
         * If userInfo.require2FA then the user has to go through the 2fa flow
         * else just login the user without 2fa
         * if require2FA is enabled check if the user has setup the auth device.
         * if setup complete then ask him to verify code and redirect to app.
         * else take him to setup the auth code via QR
         */
        if (response.userInfo.require2FA) {
          if (response.userInfo.setupComplete2FA) {
            this.router.navigate([ROUTES.VERIFY_2FA.url]);
          } else {
            this.router.navigate([ROUTES.SETUP_2FA.url]);
          }
        } else if (!noRedirect) {
          this.router.navigate([this.authorizationClient.landingPage]);
        }
        return response;
      })
    );
  }

  logOut() {
    if (!this.authorizationClient.accessToken || this.authorizationClient.sessionExpired) {
      this.removeUserSessionAndRedirectToLogin();
    }
    return this.authorizationService.logOut().pipe(
      tap((response) => {
        this.removeUserSessionAndRedirectToLogin();
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  refreshToken() {
    this.authorizationService
      .refreshToken()
      .pipe(
        tap((response) => {
          this.authorizationClient.refreshUserSession(response);
        }),
        catchError((error) => {
          throw error;
        })
      )
      .subscribe();
  }

  async verifyToken(type: string, token: string) {
    try {
      return await this.verifyTokenAsync(token);
    } catch (error) {
      if (type === ROUTES.PASSWORD_RESET.type) {
        this.router.navigate([ROUTES.FORGOT_PASSWORD.path]);
      } else if (type === ROUTES.USER_ACTIVATION.type) {
        this.router.navigate([ROUTES.PAGE_400V.path]);
      }
    }
  }

  public removeUserSessionAndRedirectToForgottenPassword() {
    this.removeUserSessionAndRedirectTo(ROUTES.FORGOT_PASSWORD.path);
  }

  public removeUserSessionAndRedirectToLogin() {
    this.removeUserSessionAndRedirectTo(ROUTES.LOGIN.path);
  }

  private verifyTokenAsync(token: string) {
    return this.authorizationService.verifyToken(token).toPromise();
  }

  private removeUserSessionAndRedirectTo(path: string) {
    this.authorizationClient.removeUserSession();
    this.router.navigate([path]);
  }
}
