import { NovatiqAuthorizationClient } from '@/_auth-client';
import { ROUTES } from '@/_contants';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
  items!: MenuItem[];
  @Output() menuclicked: EventEmitter<any> = new EventEmitter();

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;
  userName: any;

  constructor(
    public layoutService: LayoutService,
    private readonly authorizationClient: NovatiqAuthorizationClient,
    public translate: TranslateService
  ) {
    this.items = [
      {
        label: ROUTES.SUPPORT.name,
        tooltip: ROUTES.SUPPORT.name,
        icon: 'pi support-icon',
        routerLink: [ROUTES.SUPPORT.url]
      },
      {
        label: 'Logout',
        icon: 'pi pi-sign-out mx-2',
        command: () => {
          this.logoutCalled();
        }
      }
    ];

    this.userName = this.authorizationClient.loggedInUser.name || this.authorizationClient.loggedInUser.userName;
  }

  logoutCalled() {
    this.menuclicked.emit('logout');
  }
}
