import { Injectable } from '@angular/core';
import { UserManagementService } from '@/_services';
import { NovatiqDataTableHelper } from './novatiq-data-table.helper';

@Injectable({
  providedIn: 'root'
})
export class ViewListHelper {
  constructor(
    private readonly userManagementService: UserManagementService,
    private readonly dataTableHelper: NovatiqDataTableHelper
  ) {}

  applyFilter(initialDataSource, tableHeader, filterCriteria): any[] {
    const filteredDataSource = this.dataTableHelper.applyFilter(initialDataSource, filterCriteria);
    return filteredDataSource;
  }

  generateExistingFieldList(listData, field): any[] {
    const existingFieldList = [];
    Object.keys(listData).forEach((item) => {
      return existingFieldList.push(String(listData[item][field]).toLocaleLowerCase().trim());
    });
    return existingFieldList;
  }

  searchUser(filteredDataSource, tableHeader, searchQuery, searchAllColumn = true): any[] {
    const pageDataSource = this.dataTableHelper.searchUser(
      filteredDataSource,
      tableHeader,
      searchAllColumn,
      searchQuery
    );
    return pageDataSource;
  }

  sortAscending(data: any[], field): any[] {
    return data.sort((a, b) => {
      return a[field].localeCompare(b[field]);
    });
  }
}
