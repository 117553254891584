import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { NovatiqMessageService } from '../service/message.service';

@Component({
  templateUrl: './authorization.layout.html',
  styleUrls: ['./authorization.layout.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthorizationLayoutComponent implements OnDestroy {
  currentYear: number = new Date().getFullYear();
  message: any = {};
  subscription: Subscription;

  constructor(
    private novatiqMessageService: NovatiqMessageService,
    private messageService: MessageService
  ) {
    // subscribe to app component messages
    this.subscription = this.novatiqMessageService.getMessage().subscribe((message) => {
      if (message) {
        this.messageService.clear();
        this.messageService.add({ severity: message.severity, summary: '', detail: message.text, life: 5000 });
      } else {
        this.messageService.clear();
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  privacyPolicy() {
    window.open('https://novatiq.com/privacy-policy/', '_blank');
  }

  termsAndConditions() {
    window.open('https://novatiq.com/terms-conditions/', '_blank');
  }
}
