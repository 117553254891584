import { CampaignService } from '@/_services';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignDashboardHelper {
  count: number;
  glossaryList: any;
  keys: any;
  audience_keys: any;
  audience_keys_count: any;
  public isEdit = new BehaviorSubject<boolean>(false);
  constructor(private readonly campaignService: CampaignService) {}

  getGeography() {
    return this.campaignService.getGeography();
  }

  applyFilter(initialDataSource, filterCriteria): any[] {
    return initialDataSource.filter((item) => {
      const notMatchingField = Object.keys(filterCriteria).find(
        (key) => item[key] !== filterCriteria[key] && filterCriteria[key] !== undefined && filterCriteria[key] !== null
      );
      return !notMatchingField;
    });
  }

  searchCampaign(rows: any, val: any) {
    const value = val.toString().toLowerCase().trim();
    const campaignFields = [
      'campaignName',
      'externalName',
      'status',
      'clientName',
      'spendingCap',
      'totalSpend',
      'totalImpression',
      'cpm',
      'pricingFormat',
      'frequencyCap',
      'impressionsCap'
    ];
    const audienceFields = ['cpm', 'size', 'sevenDay', 'thirtyDay', 'name', 'audienceCode'];
    this.keys = campaignFields;
    this.audience_keys = audienceFields;
    this.count = this.keys.length;
    this.audience_keys_count = this.audience_keys.length;
    return rows.filter((item) => {
      let isValuePresent = false;
      for (let i = 0; i < this.count; i++) {
        if (item.status === 'pending' || item.status === 'saved') {
          if (this.keys[i] != 'campaignID' && this.keys[i] != 'endDate') {
            if (item.cpm === null) {
              item.cpm = '0.00';
            }
            if (item.status == 'stopped') {
              item.status = 'archived';
            }
            if ((item[this.keys[i]] && item[this.keys[i]].toString().toLowerCase().indexOf(value) !== -1) || !value) {
              isValuePresent = true;
            }
          }
        } else if (
          item.status === 'active' ||
          item.status === 'paused' ||
          item.status === 'stopped' ||
          item.status === 'archived'
        ) {
          if (this.keys[i] != 'campaignID' && this.keys[i] != 'startDate') {
            if (item.cpm === null) {
              item.cpm = '0.00';
            }
            if (item.status == 'stopped') {
              item.status = 'archived';
            }
            if ((item[this.keys[i]] && item[this.keys[i]].toString().toLowerCase().indexOf(value) !== -1) || !value) {
              isValuePresent = true;
            }
          }
        }
      }
      if (
        item.buySide &&
        item.buySide?.friendlyName &&
        item.buySide?.friendlyName?.toString().toLowerCase().indexOf(value) !== -1
      ) {
        isValuePresent = true;
      }
      if (
        item.agency &&
        item.agency?.friendlyName &&
        item.agency?.friendlyName?.toString().toLowerCase().indexOf(value) !== -1
      ) {
        isValuePresent = true;
      }

      const audiences = item.audiences;
      if (audiences != null) {
        for (let j = 0; j < this.audience_keys_count; j++) {
          audiences.filter((seg) => {
            if (seg.size === null) {
              seg.size = 'Pending';
            }
            if (seg.sevenDay === null) {
              seg.sevenDay = 'Pending';
            }
            if (seg.thirtyDay === null) {
              seg.thirtyDay = 'Pending';
            }
            if (
              (seg[this.audience_keys[j]] &&
                seg[this.audience_keys[j]].toString().toLowerCase().indexOf(value) !== -1) ||
              !value
            ) {
              isValuePresent = true;
            }
          });
        }
      }

      if (isValuePresent == true) {
        return true;
      }
    });
  }

  sortAscending(data: any[], field): any[] {
    return data.sort((a, b) => {
      return a[field].localeCompare(b[field]);
    });
  }

  editCampign(edit: boolean) {
    this.isEdit.next(edit);
  }
}
