export class EnvTypeConfig {
  readonly type: EnvType.TYPE;

  constructor(type: EnvType.TYPE) {
    this.type = type;
  }

  isQAEnv(): boolean {
    return this.type == EnvType.TYPE.TESTING;
  }

  isDevEnv(): boolean {
    return this.type == EnvType.TYPE.DEV;
  }
}

export namespace EnvType {
  export enum TYPE {
    DEV = 'dev',
    TESTING = 'novatiq-testing',
    STAGING = 'novatiq-staging',
    DEMO = 'novatiq-demo',
    PRODUCTION = 'novatiq-production'
  }
}
