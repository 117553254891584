import { ADMINISTRATION_API_URL as API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { IGetOrganisation, ISaveOrganisation, ISaveTitle, IUpdateOrganisation, IUpdateTitle } from '@/_types';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganisationManagementService {
  private $publishers: BehaviorSubject<IGetOrganisation[]> = new BehaviorSubject<any>(null);

  constructor(private readonly http: NovatiqHttpClient) {}

  public getPublishers() {
    return this.http.get(API_URL.PUBLISHER);
  }

  public postPublishers(body: ISaveOrganisation) {
    return this.http.post(API_URL.PUBLISHER, body);
  }

  public putPublishers(body: IUpdateOrganisation, publisherId) {
    return this.http.put(API_URL.PUBLISHER + `/${publisherId}`, body);
  }

  public getTitles() {
    return this.http.get(API_URL.TITLES);
  }

  getPublishers$() {
    return this.$publishers.asObservable();
  }

  setPublishers(data: IGetOrganisation[]) {
    this.$publishers.next(data);
  }

  postTitles(body: ISaveTitle) {
    return this.http.post(API_URL.TITLES, body);
  }

  updateTitles(body: IUpdateTitle, titleId) {
    return this.http.put(API_URL.TITLES + `/${titleId}`, body);
  }
}
