import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NovatiqPopupHelper {
  private readonly showPopup = new BehaviorSubject<string>(null);

  close() {
    this.showPopup.next(null);
  }

  getPopup() {
    return this.showPopup.asObservable();
  }

  open(id: string) {
    this.showPopup.next(id);
  }
}
