<router-outlet> </router-outlet>
@if (currentUser && isShowPopup) {
  <div>
    <app-novatiq-popup
      [title]="'expiryLockPopupTitle' | translate"
      [showFooter]="false"
      [id]="'app-idle-logout'"
      [defaultClose]="false"
    >
      <div slot="popup-body">
        <novatiq-expiry-lock [currentUser]="currentUser" (resumeSession)="resumeUserSession($event)">
        </novatiq-expiry-lock>
      </div>
    </app-novatiq-popup>
  </div>
}
<novatiq-spinner></novatiq-spinner>
