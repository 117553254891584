import { Injectable } from '@angular/core';
import { NovatiqHttpClient } from '@/_http-client';
import { AUTHORIZATION_API_URL as API_URL, LANGUAGE_CODE } from '@/_contants';
import { GlossaryModel } from '@/_types';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public addGlossary(glossary: GlossaryModel) {
    return this.http.post(`${API_URL.GET_GLOSSARY}/${LANGUAGE_CODE.EN}`, glossary);
  }

  public deleteGlossary(glossary: GlossaryModel) {
    return this.http.delete(`${API_URL.GET_GLOSSARY}/${LANGUAGE_CODE.EN}/${glossary.id}`);
  }

  public getGlossary() {
    return this.http.get(`${API_URL.GET_GLOSSARY}/${LANGUAGE_CODE.EN}`);
  }
  public getManual() {
    const httpOptions = {
      responseType: 'blob'
    };
    return this.http.getFile(API_URL.GET_SUPPORT_MANUAL + LANGUAGE_CODE.EN, httpOptions);
  }
  public saveManual(data: any) {
    return this.http.post(API_URL.GET_SUPPORT_MANUAL + LANGUAGE_CODE.EN, data);
  }
  public saveGlossary(glossary: GlossaryModel) {
    return this.http.put(`${API_URL.GET_GLOSSARY}/${LANGUAGE_CODE.EN}/${glossary.id}`, glossary);
  }
}
