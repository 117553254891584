<ul class="layout-menu">
  @for (item of model; track item; let i = $index) {
    @if (!item.separator) {
      <li app-menuitem [item]="item" [index]="i" [root]="true"></li>
    }
    @if (item.separator) {
      <li class="menu-separator"></li>
    }
  }
</ul>
