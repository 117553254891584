import { AUTHORIZATION_CLIENT } from '@/_contants';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NovatiqMediaTypeHelper {
  private readonly $mediaType: BehaviorSubject<MediaType>;

  constructor() {
    this.$mediaType = new BehaviorSubject<MediaType>(this._getMediaType());
  }

  clear() {
    this.$mediaType.next(null);
  }

  private _getMediaType(): MediaType {
    return <MediaType>localStorage.getItem(AUTHORIZATION_CLIENT.MEDIA_TYPE);
  }

  getMediaType() {
    return this.$mediaType.asObservable();
  }

  setMediaType(mediaType: MediaType) {
    this.$mediaType.next(mediaType);
    localStorage.setItem(AUTHORIZATION_CLIENT.MEDIA_TYPE, mediaType);
  }
}

export type MediaType = MediaTypes.Display | MediaTypes.Video;
export enum MediaTypes {
  Display = 'Display',
  Video = 'Video'
}
