import { APP } from '@/_contants';
import { PricingService } from '@/_services';
import { AttributePricingModel } from '@/_types/models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewPricingHelper {
  pricingDetails: AttributePricingModel;
  regex: any;

  constructor(private readonly pricingService: PricingService) {}

  customNumberValidation(event: any, type: string) {
    this.regex = type === 'custom' ? new RegExp(APP.CUSTOM_NUMBER_PATTERN) : new RegExp(APP.DECMIAL_NUMBER_PATTERN);

    const current = event.target.value;
    const position = event.target.selectionStart;
    const next = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join(
      ''
    );
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  getGeography() {
    return this.pricingService.getGeography();
  }

  generateDataSource(pricing: AttributePricingModel[]) {
    const generateResultSet = [];
    pricing.forEach((i) => {
      generateResultSet.push({
        attributeId: i.attributeId,
        attributeName: i.attributeName,
        discount:
          i.Discount === 'null' || i.Discount === 'undefined' || (!i.USD && !i.GBP && !i.EUR && !i.TL && !i.AED)
            ? ''
            : i.Discount,
        USD: i?.USD === undefined || i.USD === 'null' ? '' : i.USD,
        GBP: i?.GBP === undefined || i.GBP === 'null' ? '' : i.GBP,
        EUR: i?.EUR === undefined || i.EUR === 'null' ? '' : i.EUR,
        TL: i?.TL === undefined || i.TL === 'null' ? '' : i.TL,
        AED: i?.AED === undefined || i.AED === 'null' ? '' : i.AED
      });
    });
    return generateResultSet;
  }

  getPricing(countryId: string) {
    return this.pricingService.getPricingAttributes(countryId);
  }

  getPricingDiscount(countryId: string) {
    return this.pricingService.getPricingAttributesDiscount(countryId);
  }

  getCurrencies() {
    return this.pricingService.getCurrencies();
  }
}
