import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorHandlingHelper } from '@/_helpers';

@Injectable()
export class NovatiqHttpErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private readonly errorHandlingHelper: ErrorHandlingHelper) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.errorHandlingHelper.handleApiError(error);
      })
    );
  }
}
