import { NovatiqAuthorizationClient } from '@/_auth-client';
import { ROUTES } from '@/_contants';
import { AuthorizationHelper, NovatiqPopupHelper } from '@/_helpers';
import { PageSettingService } from '@/_services';
import { LoginModel, UserInfoModel } from '@/_types';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'novatiq-expiry-lock',
  templateUrl: './novatiq-expiry-lock.component.html'
})
export class NovatiqExpiryLockComponent implements OnInit, OnDestroy {
  @Input() currentUser: UserInfoModel;

  @Output() resumeSession: EventEmitter<any>;

  apiError: string;
  loginForm: UntypedFormGroup;
  pageSettings: any;

  get formControls() {
    return this.loginForm.controls;
  }

  private get loginModel() {
    return {
      username: this.modelData.username,
      password: this.modelData.password
    } as LoginModel;
  }

  private get modelData() {
    return this.loginForm.value;
  }

  constructor(
    private readonly authorizationHelper: AuthorizationHelper,
    private readonly AuthorizationClient: NovatiqAuthorizationClient,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly pageSettingService: PageSettingService,
    private readonly popupHelper: NovatiqPopupHelper,
    public translate: TranslateService,
    private readonly router: Router
  ) {
    this.resumeSession = new EventEmitter<any>();
  }

  ngOnInit() {
    this.getPageSettings();
    this.initializeProperties();
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.authorizationHelper.logIn(this.loginModel, true).subscribe(
      (data) => {
        this.resumeSession.emit(data);
        this.loginForm.controls?.password?.setValue('');
        this.popupHelper.close();
      },
      (error) => {
        this.apiError = error.message;
      }
    );
  }

  forgotPassword() {
    this.popupHelper.close();
    this.authorizationHelper.removeUserSessionAndRedirectToForgottenPassword();
  }

  private getPageSettings() {
    this.pageSettingService.get(ROUTES.LOGIN.title.trim().toLowerCase()).subscribe((settings) => {
      this.pageSettings = settings;
    });
  }

  private initializeProperties() {
    this.apiError = '';
    this.loginForm = this.formBuilder.group({
      username: [this.currentUser?.userName],
      password: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.loginForm.reset();
  }
}
