import { AttributeOnboardAndManageService } from '@/_services';
import { IAttributeCategory } from '@/_types';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AttributeCategoryHelper {
  private ParentCategories$: BehaviorSubject<IAttributeCategory[]> = new BehaviorSubject(null);
  private AttributeCategories$: BehaviorSubject<IAttributeCategory[]> = new BehaviorSubject(null);
  DISABLED_CATEGORIES = ['']; //'Bespoke'
  constructor(private readonly attributeOAMService: AttributeOnboardAndManageService) {}

  resetAttributeCategories() {
    this.AttributeCategories$.next(null);
  }

  getAttributeCategories() {
    return this.AttributeCategories$.asObservable();
  }

  getParentCategories() {
    return this.ParentCategories$.asObservable();
  }

  setAttributeCategories(profile: IAttributeCategory[]) {
    this.AttributeCategories$.next(profile);
  }

  setAttributeCategoriesFromService() {
    return this.attributeOAMService.getAttributeCategories().subscribe((data) => {
      this.AttributeCategories$.next(data);

      // get unique objects from the list based on parent_category
      let categories = <IAttributeCategory[]>[...new Map(data.map((item) => [item['parent_category'], item])).values()];

      // add active,enabled property
      categories = categories.map((category) => ({ ...category, active: false, enabled: true }));

      // disable parent_category listed in DISABLED_CATEGORIES
      categories = categories.map((category) =>
        this.DISABLED_CATEGORIES.includes(category.parent_category) ? { ...category, enabled: false } : category
      );
      this.ParentCategories$.next(categories);
    });
  }

  recreateParentAttributeCategoryList(attributes) {
    /** create primaryCategoryList from list of attributes */
    let primaryCategoryList = [];

    for (let i = 0; i < attributes.length; i++) {
      if (!!attributes[i].categories.length) {
        for (let j = 0; j < attributes[i].categories.length; j++) {
          primaryCategoryList.push(attributes[i].categories[j]);
        }
      }
    }

    /** reorder it */
    const reOrderinglist = ['Demographic', 'Interest', 'Purchase Intent', 'Apps', 'Bespoke'];
    let actionList = [];

    this.ParentCategories$.pipe(take(1)).subscribe((AttributeCategories) => {
      reOrderinglist.forEach((element) => {
        AttributeCategories.forEach((action) => {
          if (action.parent_category === element) {
            actionList.push(action);
          }
        });
      });

      for (let i = 0; i < AttributeCategories.length; i++) {
        if (!reOrderinglist.includes(AttributeCategories[i].parent_category)) {
          actionList.push(AttributeCategories[i]);
        }
      }

      // add active,enabled property
      actionList = actionList.map((category) => ({ ...category, active: false, enabled: true }));

      // disable parent_category listed in DISABLED_CATEGORIES
      actionList = actionList.map((category) =>
        this.DISABLED_CATEGORIES.includes(category.parent_category) ? { ...category, enabled: false } : category
      );
      this.ParentCategories$.next(actionList);
    });
  }
}
