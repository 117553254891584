export enum ATTRIBUTETYPE {
  'SINGLE' = 'Single',
  'LOCATION' = 'Location',
  'RANGED' = 'Ranged',
  'MULTI' = 'Multi'
}

export enum DATATYPE {
  'Map' = 'Map',
  'String' = 'String',
  'Interval' = 'Interval',
  'Numeric' = 'Numeric'
}

export enum ATTRIBUTEORDERTYPE {
  'single' = 'Single',
  'location' = 'Location',
  'ranged' = 'Ranged',
  'multi' = 'Multi'
}

export enum BESPOKEATTRIBUTETYPE {
  'SINGLE' = 'Single',
  'MULTI' = 'Multi'
}
