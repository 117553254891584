import { NovatiqAuthorizationClient } from '@/_auth-client';
import { APPLICATION_MODE } from '@/_contants';
import {
  AuthorizationHelper,
  CommonAPIHelper,
  MapSideBarData,
  NovatiqGeographyHelper,
  NovatiqPopupHelper,
  ToastorHelper
} from '@/_helpers';
import { PageSettingService } from '@/_services';
import { AppStorage } from '@/_storage';
import { NovatiqError } from '@/_types';
import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopBarComponent } from './app.topbar.component';
import { LayoutService } from './service/app.layout.service';
import { NovatiqMessageService } from './service/message.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html'
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  overlayMenuOpenSubscription: Subscription;

  menuOutsideClickListener: any;

  profileMenuOutsideClickListener: any;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  @ViewChild(AppTopBarComponent) appTopbar!: AppTopBarComponent;

  public apiError: string;
  pageSettings: any;
  isLogout: boolean;
  public pageSettingSubscription: Subscription;
  private readonly unsavedchangedSubscription: Subscription;
  public userName: string;
  geographySubscription: Subscription;

  message: any = {};
  messageSubscription: Subscription;

  constructor(
    public layoutService: LayoutService,
    public renderer: Renderer2,
    public router: Router,
    private readonly authorizationHelper: AuthorizationHelper,
    private readonly authorizationClient: NovatiqAuthorizationClient,
    private readonly dataStorage: AppStorage,
    private readonly mapSideBarData: MapSideBarData,
    private readonly popupHelper: NovatiqPopupHelper,
    private readonly toaster: ToastorHelper,
    private readonly pageSettingService: PageSettingService,
    public translate: TranslateService,
    public commonAPIHelper: CommonAPIHelper,
    public _geographyHelper: NovatiqGeographyHelper,
    private novatiqMessageService: NovatiqMessageService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    translate.addLangs(['en', 'de', 'fr']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/en|de|fr/) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }

    this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe(() => {
      if (!this.menuOutsideClickListener) {
        this.menuOutsideClickListener = this.renderer.listen('document', 'click', (event) => {
          const isOutsideClicked = !(
            this.appSidebar.el.nativeElement.isSameNode(event.target) ||
            this.appSidebar.el.nativeElement.contains(event.target) ||
            this.appTopbar.menuButton.nativeElement.isSameNode(event.target) ||
            this.appTopbar.menuButton.nativeElement.contains(event.target) ||
            event.target.classList.contains('collapsible-icon')
          );

          if (isOutsideClicked) {
            this.hideMenu();
          }
        });
      }

      if (!this.profileMenuOutsideClickListener) {
        this.profileMenuOutsideClickListener = this.renderer.listen('document', 'click', (event) => {
          const isOutsideClicked = !(
            this.appTopbar.menu.nativeElement.isSameNode(event.target) ||
            this.appTopbar.menu.nativeElement.contains(event.target) ||
            this.appTopbar.topbarMenuButton.nativeElement.isSameNode(event.target) ||
            this.appTopbar.topbarMenuButton.nativeElement.contains(event.target) ||
            event.target.classList.contains('collapsible-icon')
          );

          if (isOutsideClicked) {
            this.hideProfileMenu();
          }
        });
      }

      if (this.layoutService.state.staticMenuMobileActive) {
        this.blockBodyScroll();
      }
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.hideMenu();
      this.hideProfileMenu();
    });

    // subscribe to app component messages
    this.messageSubscription = this.novatiqMessageService.getMessage().subscribe((message) => {
      if (message) {
        this.messageService.add({ severity: message.severity, summary: '', detail: message.text });
      } else {
        this.messageService.clear();
      }
    });
  }

  ngOnInit() {
    this.initializeProperties();
    this.initializeSubscription();
  }

  cancel() {
    this.popupHelper.close();
  }

  private initializeProperties() {
    this.apiError = '';
    this.isLogout = false;
    this.userName = this.authorizationClient.loggedInUser.name;
  }

  initializeSubscription() {
    if (this.isLogout) {
      this.confirmationService.confirm({
        message: this.translate.instant('LogoutMessage'),
        accept: () => {
          this.logOut();
        }
      });
    } else {
      this.isLogout = false;
    }
  }

  menuClicked(evt) {
    if (evt === 'logout') {
      this.getConfirmation();
    }
  }

  privacyPolicy() {
    window.open('https://novatiq.com/privacy-policy/', '_blank');
  }

  termsAndConditions() {
    window.open('https://novatiq.com/terms-conditions/', '_blank');
  }

  hideMenu() {
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = false;
    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }
    this.unblockBodyScroll();
  }

  hideProfileMenu() {
    this.layoutService.state.profileSidebarVisible = false;
    if (this.profileMenuOutsideClickListener) {
      this.profileMenuOutsideClickListener();
      this.profileMenuOutsideClickListener = null;
    }
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  }

  get containerClass() {
    return {
      'layout-theme-light': this.layoutService.config.colorScheme === 'light',
      'layout-theme-dark': this.layoutService.config.colorScheme === 'dark',
      'layout-overlay': this.layoutService.config.menuMode === 'overlay',
      'layout-static': this.layoutService.config.menuMode === 'static',
      'layout-static-inactive':
        this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === 'static',
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'p-input-filled': this.layoutService.config.inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config.ripple
    };
  }

  get collapsibleClass() {
    if (this.layoutService.config.menuMode === 'static') {
      if (this.layoutService.state.staticMenuDesktopInactive) {
        return 'pi pi-angle-right collapsible-icon';
      } else {
        return 'pi pi-angle-left collapsible-icon';
      }
    }
  }

  getConfirmation() {
    this.isLogout = true;
    this.dataStorage.RemoveItem(APPLICATION_MODE.EDIT_MODE);
    if (this.dataStorage.ContainsKey(APPLICATION_MODE.EDIT_MODE)) {
      this.popupHelper.open('unsavedChanges');
    } else {
      this.confirmationService.confirm({
        message: this.translate.instant('LogoutMessage'),
        accept: () => {
          this.logOut();
        }
      });
    }
  }

  logOut() {
    this.popupHelper.close();
    this.authorizationHelper
      .logOut()
      .pipe(
        catchError((error: NovatiqError) => {
          this.toaster.errorMessage(error.message);
          return throwError(error);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.messageSubscription.unsubscribe();

    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }
}
