import { API_ERROR_CODE, PAGE_SETTING_URL } from '@/_contants';
import { HTTP_ERROR_CODE, NovatiqError } from '@/_types';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { AuthorizationHelper } from '../authorization';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingHelper {
  constructor(
    private readonly authorizationHelper: AuthorizationHelper,
    private readonly router: Router
  ) {}

  handleApiError(_httpErrorResponse: HttpErrorResponse): Observable<never> {
    if (_httpErrorResponse.error) {
      const hasGoAPIErrors = !!_httpErrorResponse.error.errors;
      const hasJavaAPIErrors = !!_httpErrorResponse.error.errorInfo;
      const hasAssetErrors = _httpErrorResponse.url.includes(PAGE_SETTING_URL.CONFIGURATION_URL);
      if (hasAssetErrors || (!hasGoAPIErrors && !hasJavaAPIErrors)) {
        return throwError({
          code: _httpErrorResponse.status,
          message: _httpErrorResponse.message,
          class: _httpErrorResponse.name
        });
      }
      // GO API error format
      else if (hasGoAPIErrors) {
        const errorText = _httpErrorResponse.error.errors
          .map((error) => `Field '${error.field}' has error :  ${error.error}`)
          .join('\n');
        return throwError({
          code: _httpErrorResponse.status,
          message: errorText,
          class: _httpErrorResponse.name
        });
      }
      // JAVA API error format
      else if (hasJavaAPIErrors) {
        const apiError = _httpErrorResponse.error.errorInfo;
        if (apiError.errorCode === HTTP_ERROR_CODE.AUTHENTICATION_TOKEN_EXPIRED) {
          this.authorizationHelper.removeUserSessionAndRedirectToLogin();
        }
        return throwError({
          code: apiError.errorCode,
          message: API_ERROR_CODE[apiError.errorCode] || apiError.errorMessage,
          class: apiError.errorClass,
          responseData: _httpErrorResponse.error?.responseData
        } as NovatiqError);
      }
    } else {
      //return generic error from http response
      return throwError({
        code: _httpErrorResponse.status,
        message: _httpErrorResponse.message,
        class: _httpErrorResponse.name
      });
    }
  }

  handleError(error: any) {
    throw error;
  }
}
