export const ROUTES = {
  ADD_USER_ROLES: {
    title: 'adduserrole'
  },
  ADD_GLOSSARY: {
    title: 'addglossary'
  },
  ADMINISTRATION: {
    name: 'Administration',
    path: 'administration',
    url: '/administration'
  },
  APP: {
    title: 'app'
  },
  ATTRIBUTE_ONBOARDING: {
    name: 'Attribute Onboarding',
    title: 'Attribute Onboarding',
    path: 'attributeonboarding',
    url: '/administration/attributeonboarding'
  },
  BASE_URL: '/',
  BLOCKLIST: {
    title: 'blocklist',
    name: 'Blocklists'
  },
  COMMERCIAL: {
    name: 'Commercial',
    path: 'commercialperformance',
    title: 'Commercial Performance',
    url: '/reporting/commercialperformance'
  },
  CREATOR: {
    name: 'Reporting',
    path: 'creator',
    url: '/creator'
  },
  CAMPAIGN_BUILDER: {
    name: 'Campaign Builder',
    path: 'campaignbuilder',
    title: 'Campaign Builder',
    url: '/campaignbuilder'
  },
  CAMPAIGN_BUILDER_GEOGRAPHY: {
    name: 'Campaign Builder',
    path: 'campaignbuilder/:geography',
    title: 'Campaign Builder',
    url: '/campaignbuilder'
  },
  CAMPAIGN_BUILDER_GEOGRAPHY_FORMAT: {
    name: 'Campaign Builder',
    path: 'campaignbuilder/:geography/:format',
    title: 'Campaign Builder',
    url: '/campaignbuilder'
  },
  CAMPAIGN_BUILDER_EDIT: {
    name: 'Campaign Builder',
    path: 'campaignbuilder/campaign/:id',
    title: 'Campaign Builder',
    url: '/campaignbuilder/campaign'
  },
  EDIT_LAUNCH_CAMPAIGN: {
    name: 'Campaign Builder',
    path: 'campaignbuilder/campaign/launch/:id',
    title: 'Campaign Builder',
    url: '/campaignbuilder/campaign/launch'
  },
  CAMPAIGN_DASHBOARD: {
    name: 'Campaign Dashboard',
    path: 'campaigndashboard',
    title: 'Campaign Dashboard',
    url: '/campaigndashboard'
  },
  ZENITH_BILLING: {
    name: 'Zenith Billing',
    path: 'zenithbilling',
    title: 'Zenith Billing',
    url: '/zenithbilling'
  },
  ORGANISATION_MANAGEMENT: {
    name: 'Organisation Management',
    path: 'organisations',
    title: 'Organisation Management',
    url: '/administration/organisations'
  },
  CLIENT_MANAGEMENT: {
    name: 'Client Management',
    path: 'clientmanagement',
    title: 'Client Management',
    url: '/administration/clientmanagement'
  },
  ZENITH_MANAGEMENT: {
    name: 'Zenith Management',
    path: 'zenith',
    title: 'Zenith Management',
    url: '/administration/zenith'
  },
  CREATE_PASSWORD: {
    path: 'createpassword',
    title: 'Create Password'
  },
  CREATOR_ADMIN: {
    name: 'Attribute Management',
    path: 'creatoradmin',
    title: 'Attribute Management',
    url: '/administration/creatoradmin'
  },
  CSS_HELPER_CLASS: {
    path: 'style',
    title: 'Style'
  },
  CATAGORY_BLOCKLISTS: {
    name: 'Blocklists',
    path: 'blocklists',
    url: '/administration/blocklists',
    title: 'Blocklists'
  },
  CONTACT_US: {
    title: 'contactus'
  },
  CONFIRM_UPLOAD: {
    title: 'confirmupload'
  },
  DASHBOARD: {
    name: 'Dashboard',
    path: 'dashboard',
    title: 'Dashboard',
    url: '/dashboard'
  },
  DATA_USAGE: {
    name: 'Data Usage',
    path: 'datausage',
    title: 'Data Usage',
    url: '/reporting/datausage'
  },
  DEFAULT_LAYOUT: {
    name: 'DefaultLayout',
    path: '',
    title: 'home',
    url: '/'
  },
  DELETE_CATEGORY_BLACKLIST: {
    title: 'deletecategoryblocklist'
  },
  DELETE_SSPID: {
    title: 'deletesspid'
  },
  DELETE_USER_ROLES: {
    title: 'deleteuserrole'
  },
  EDIT_USER: {
    title: 'edituser'
  },
  EMPTY_URL: '',
  FORGOT_PASSWORD: {
    path: 'forgotpassword',
    title: 'Forgot Password'
  },
  INVITE_USER: {
    title: 'inviteuser'
  },
  CREATE_SSPID: {
    title: 'createsspid'
  },
  EDIT_SSPID: {
    title: 'editsspid'
  },
  LEAVE_CONFIRMATION: {
    path: 'leaveconfirmation',
    title: 'LeaveConfirmation'
  },
  LOGIN: {
    path: 'login',
    title: 'Login'
  },
  LANDING_PAGE: {
    name: 'Landing Page'
  },
  OTHERS: '**',
  PAGE_400V: {
    path: '400v',
    title: 'User verification link expired'
  },
  PAGE_401: {
    path: '401',
    title: 'Un Authorized'
  },
  PAGE_404: {
    path: '404',
    title: 'Page Not Found'
  },
  PAGE_500: {
    path: '500',
    url: '500',
    title: 'Internal Server Error'
  },
  PASSWORD_RESET: {
    path: 'passwordResetLink/:token',
    type: 'passwordreset'
  },
  PRICING: {
    name: 'Ratecard Pricing',
    path: 'pricing',
    url: '/administration/pricing',
    title: 'Ratecard Pricing'
  },
  REGISTER: {
    path: 'register',
    title: 'Register'
  },
  REPORTING: {
    name: 'Reporting',
    path: 'reporting',
    url: '/reporting'
  },
  AUDIENCE_BUILDER: {
    name: 'Audience Builder',
    path: 'audiencebuilder',
    title: 'Audience Builder',
    url: '/audiencebuilder'
  },
  AUDIENCE_BUILDER_ID: {
    name: 'Audience Builder',
    path: 'audiencebuilder/:id',
    title: 'Audience Builder',
    url: '/audiencebuilder'
  },
  SSPIDMANAGEMENT: {
    name: 'SSP ID Management',
    path: 'sspidmanagement',
    url: '/administration/sspidmanagement',
    title: 'SSP ID Management'
  },
  SUPPORT: {
    name: 'Support',
    path: 'support',
    url: '/administration/support',
    title: 'Support'
  },
  SYSTEM_PERFORMANCE: {
    name: 'System Performance',
    path: 'systemperformance',
    url: '/reporting/systemperformance',
    title: 'System Performance'
  },
  UNSAVED_CHANGE_CONFIRMATION: {
    title: 'unsavedchangeconfirmation'
  },
  USER_ACTIVATION: {
    path: 'activationLink/:token',
    type: 'useractivation'
  },
  USER_MANAGEMENT: {
    name: 'User Management',
    path: 'usermanagement',
    url: '/administration/usermanagement',
    title: 'User Management'
  },
  USER_ROLES: {
    name: 'User Roles',
    path: 'role',
    title: 'User Roles',
    url: '/administration/role'
  },
  SETUP_2FA: {
    path: 'setup2fa',
    title: 'Setup 2FA',
    url: '/setup2fa'
  },
  VERIFY_2FA: {
    path: 'verify2fa',
    title: 'Verify 2FA',
    url: '/verify2fa'
  },
  PRESET_MANAGEMENT: {
    name: 'Preset Management',
    path: 'presetmanagement',
    title: 'Preset Management',
    url: '/administration/presetmanagement'
  },
  BESPOKE_ATTRIBUTE_MANAGEMENT: {
    name: 'Bespoke Attribute Management',
    path: 'bespokemanagement',
    title: 'Bespoke Attribute Management',
    url: '/administration/bespokemanagement'
  }
};
