<div class="right-sidebar-grid">
  <header class="header p-0">
    <p-messages
      styleClass="novatiq-message"
      [showTransitionOptions]="'400ms'"
      [hideTransitionOptions]="'0'"
    ></p-messages>
  </header>
  <aside class="left-content">
    <div class="fusion-platform-desc">
      <img
        src="assets/img/brand/fusion_logo1.png"
        alt="{{ 'fusion_platform' | translate }}"
        title="{{ 'fusion_platform' | translate }}"
        class="logo m-2"
      />
      <p class="logo-description m-2">{{ 'fusion_platform_desc' | translate }}</p>
    </div>
  </aside>
  <main class="main-content">
    <router-outlet></router-outlet>
  </main>
  <footer class="footer">
    <!-- Footer content here -->
    <div class="footer-left"></div>
    <div class="footer-right">
      <div class="footer-container">
        <div>© {{ currentYear }} Novatiq</div>
        <div>
          <a href="javascript:void(0);" (click)="privacyPolicy()" class="underline">
            {{ 'PrivacyPolicy' | translate }}
          </a>
        </div>
        <div>
          <a href="javascript:void(0);" (click)="termsAndConditions()" class="underline">
            {{ 'TermsAndConditions' | translate }}
          </a>
        </div>
        <div>
          <img src="assets/img/brand/fusion-alt.svg" alt="nova icon" width="35" height="35" />
        </div>
      </div>
    </div>
  </footer>
</div>
