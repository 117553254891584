import { Injectable } from '@angular/core';
import { NovatiqHttpClient } from '@/_http-client';
import { AUTHORIZATION_API_URL as API_URL } from '@/_contants';
import { BaseRoleModel, AddRoleModel } from '@/_types';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public getRoles() {
    return this.http.get(API_URL.GET_ROLES);
  }

  public saveRoles(data: AddRoleModel) {
    return this.http.post(API_URL.GET_ROLES, data);
  }

  public updateRole(roleId: number, data: BaseRoleModel) {
    return this.http.put(`${API_URL.GET_ROLES}/${roleId}`, data);
  }

  public deleteRoles(roleId: number) {
    return this.http.delete(`${API_URL.GET_ROLES}/${roleId}`);
  }
}
