import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppStorage {
  private count = 0;
  private items: { [index: string]: any } = {};

  public AddItem(key: string, value: any) {
    if (!this.items.hasOwnProperty(key)) {
      this.count++;
    }

    this.items[key] = value;
  }

  public ContainsKey(key: string): boolean {
    return this.items.hasOwnProperty(key);
  }

  public Count(): number {
    return this.count;
  }

  public GetItem(key: string): any {
    return this.items[key];
  }

  public Keys(): string[] {
    const keySet: string[] = [];

    for (const prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        keySet.push(prop);
      }
    }

    return keySet;
  }

  public RemoveItem(key: string): any {
    const val = this.items[key];

    delete this.items[key];

    this.count--;

    return val;
  }

  public Values(): any[] {
    const valueSet: any[] = [];

    for (const prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        valueSet.push(this.items[prop]);
      }
    }

    return valueSet;
  }
}
