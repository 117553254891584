import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NovatiqLoadingSpinnerHelper } from '@/_helpers';
import { AUTHORIZATION_API_URL } from '@/_contants';
import { finalize } from 'rxjs/operators';

@Injectable()
export class NovatiqLoadingSpinnerInterceptor implements HttpInterceptor {
  private readonly requests: HttpRequest<any>[] = [];

  constructor(private readonly loadingHelper: NovatiqLoadingSpinnerHelper) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    if (this.requests.length > 0) {
      this.loadingHelper.start();
    } else {
      this.loadingHelper.stop();
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);

    if (!req.url.includes(AUTHORIZATION_API_URL.REFRESH_TOKEN)) {
      this.loadingHelper.start();
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.removeRequest(req);
      })
    );
  }
}
