import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilHelper {
  clone(obj) {
    if (!obj || typeof obj !== 'object') {
      return obj;
    }
    const temp = new obj.constructor();
    Object.keys(obj).forEach((key) => {
      temp[key] = this.clone(obj[key]);
    });
    return temp;
  }

  /** Move an object in the array up or down */
  move(array, index, delta) {
    //ref: https://gist.github.com/albertein/4496103

    let newIndex = index + delta;
    if (newIndex < 0 || newIndex == array.length) return; //Already at the top or bottom.
    let indexes = [index, newIndex].sort((a, b) => a - b); //Sort the indixes (fixed)
    array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); //Replace from lowest index, two elements, reverting the order
  }

  moveUp(array, element) {
    this.move(array, element, -1);
  }

  moveDown(array, element) {
    this.move(array, element, 1);
  }
}
