import { UserManagementService } from '@/_services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReactivateHelper {
  constructor(private readonly userManagementService: UserManagementService) {}

  reactivateUser(userName: any) {
    return this.userManagementService.reactivateUser({ username: userName });
  }
}
