import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableModel } from '@/_types';
@Injectable({
  providedIn: 'root'
})
export class NovatiqDataTableHelper {
  dataTableValue = new Subject<DataTableModel>();
  public temp: Array<object> = [];
  count: number;
  keys: any;

  mapdata(data: DataTableModel) {
    this.dataTableValue.next(data);
  }

  public applyFilter(items: Array<any>, filter: Array<any>) {
    return items.filter((item) => {
      const notMatchingField = Object.keys(filter).find(
        (key) => item[key] !== filter[key] && filter[key] !== undefined && filter[key] !== null && filter[key] !== ''
      );
      return !notMatchingField;
    });
  }

  public getDataTable() {
    return this.dataTableValue.asObservable();
  }

  public generateHeader(column: any) {
    return column.map((x) => ({
      key: x.key,
      displayName: x.displayName,
      sort: x.sort,
      showIcon: x.showIcon,
      iconGroup: x.iconGroup
    }));
  }

  public searchUser(filteredRows: any = [], columns: any = [], isCheckAllColumns: boolean, val: any) {
    const value = val.toString().toLowerCase().trim();
    if (filteredRows.length > 0) {
      this.keys = isCheckAllColumns ? Object.keys(filteredRows[0]) : columns.map((x) => x['dataProperty']);
      this.count = this.keys.length;
      this.temp = filteredRows;
      return this.temp.filter((item) => {
        for (let i = 0; i < this.count; i++) {
          if ((item[this.keys[i]] && item[this.keys[i]].toString().toLowerCase().indexOf(value) !== -1) || !value) {
            return true;
          }
        }
      });
    }
  }
}
