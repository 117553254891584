import { Injectable } from '@angular/core';
import { NovatiqHttpClient } from '@/_http-client';
import { PAGE_SETTING_URL } from '@/_contants';

@Injectable({
  providedIn: 'root'
})
export class PageSettingService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public get(url: string) {
    return this.http.getLocal(`${PAGE_SETTING_URL.CONFIGURATION_URL + url}.json`);
  }
}
