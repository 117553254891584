export const AUTHORIZATION_API_URL = {
  APPLY_REQUESTED_DISCOUNT: 'apply-requested-discount',
  CLIENTS: 'clients',
  CLIENT_DISCOUNT: 'client-discount',
  CLIENT_FIXED_RATE: 'client-fixed-rate',
  CLIENT_MANAGEMENT: 'client-management',
  CLIENTS_BUYSIDE: 'clients-buyside',
  CREATE_NEW_PASSWORD: 'password/reset/send',
  COUNTRY: 'country',
  CURRENCY: 'currency',
  BUYSIDE_FIXED_RATE: 'buyside-fixed-rate',
  BUYSIDE_DISCOUNT: 'buyside-discount',
  BUYSIDE_CURRENCY: 'buyside-currency',
  DISCOUNT: 'discount',
  BUYSIDE: 'buyside',
  DISCOUNT_REQUEST: 'discountRequest',
  GET_ROLES: 'roles',
  DATA_OWNER_MNO: 'data-owners/mno',
  GET_GLOSSARY: 'support/glossary',
  GET_SUPPORT_MANUAL: 'support/manual/',
  GET_USERS: 'users',
  USER: 'user',
  GET_SSPIDS: 'ssps',
  GET_DATA_PARTNERS_CLIENTS: 'buyside/clients',
  GET_ORGANISATIONS: 'organisations',
  LOGIN: 'auth',
  LOGOUT: 'auth/logout',
  POST_USERS: 'invite/send',
  POST_SSPIDS: 'ssps',
  POST_USERS_DEACTIVATE: 'users/deactivate',
  POST_USERS_REACTIVATE: 'users/activate',
  REJECT_REQUESTED_DISCOUNT: 'reject-requested-discount',
  REFRESH_TOKEN: 'auth/refresh',
  RESET_PASSWORD: 'password/reset/complete',
  RESEND_ACTIVATION_EMAIL: 'invite/resend',
  POST_USERS_UNBLOCK: 'users/unblock',
  VERIFY_TOKEN: 'password/reset/verify',
  ORGANISATION_CLIENT: 'organisation-clients',
  DELETE_CLIENT: 'delete-client',
  ORGANISATION_BUYSIDE: 'organisation-buyside',
  SETUP_2FA: 'auth/otpsetup',
  VERIFY_2FA: 'auth/otp'
};
