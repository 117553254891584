import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionHelper {
  private get encryptionKey() {
    return CryptoJS.enc.Utf8.parse(environment.NovatiqEncryptionKey);
  }

  encrypt(value: string) {
    const numerator = 128;
    const denominator = 8;

    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), this.encryptionKey, {
      keySize: numerator / denominator,
      iv: this.encryptionKey,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  decrypt(value: string) {
    const numerator = 128;
    const denominator = 8;
    const decrypted = CryptoJS.AES.decrypt(value, this.encryptionKey, {
      keySize: numerator / denominator,
      iv: this.encryptionKey,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
