<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md mx-auto text-center">
          <div class="card-group">
            <div class="card nvcard">
              <div class="card-body">
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
