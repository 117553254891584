import { CommonAPIService } from '@/_services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonAPIHelper {
  constructor(private readonly commonAPIService: CommonAPIService) {}

  getGeographies() {
    return this.commonAPIService.getGeographies();
  }
}
