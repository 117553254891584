import { LIST_TYPE_CONSTANT } from '@/_contants';
import { CategoryBlocklistsService } from '@/_services';
import { CategoryListModel } from '@/_types';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlocklistHelper {
  categories = new Subject<CategoryListModel[]>();
  constructor(private readonly service: CategoryBlocklistsService) {}

  checkIfBlocklistExists(list: any, blocklist: any, prop: string) {
    return list.filter((item) => !blocklist.some((x) => x[prop] === item[prop]));
  }

  checkIfBrandBlocklistExists(list: any, blocklist: any, prop: string) {
    return list.filter((item) => !blocklist.some((x) => x.name === item.name));
  }

  public getCategories() {
    return this.categories.asObservable();
  }

  getCategoryList() {
    this.service.getCategories().subscribe((response) => {
      this.categories.next(response);
    });
  }

  mapBlacklistData(data: any) {
    return data.map((x) => ({ id: x.id, category: x.option, isBlacklisted: true }));
  }

  mapdata(data: any, listType: string) {
    if (listType === LIST_TYPE_CONSTANT.ALLOWLIST) {
      return data.map((x) => ({ category: x.category, operationType: LIST_TYPE_CONSTANT.ALLOWLIST }));
    }
    return data.map((x) => ({ category: x.category, operationType: LIST_TYPE_CONSTANT.BLOCKLIST }));
  }

  filterBlacklist(data) {
    return data.filter((i) => i.isBlacklisted === true);
  }
}
