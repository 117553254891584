import { AUDIENCEBUILDER_API_URL as API_URL, ATTRIBUTESONBOARDANDMANAGE_API_URL as ATTRIB_API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonAPIService } from './novatiq-commonapi.service';

@Injectable({
  providedIn: 'root'
})
export class AttributeOnboardAndManageService {
  constructor(
    private readonly http: NovatiqHttpClient,
    private readonly commonAPIService: CommonAPIService
  ) {}

  public getGeography() {
    return this.commonAPIService.getGeographies();
  }

  public createNovatiqAttribute(body) {
    return this.http.post(ATTRIB_API_URL.NOVATIQ_ATTRIBUTES, body);
  }

  public createCategories(body) {
    return this.http.post(ATTRIB_API_URL.ATTRIBUTE_CATEGORY, body);
  }

  public createNovatiqAttributeWithDataOwner(body) {
    return this.http.post(ATTRIB_API_URL.DATAOWNER_ATTRIBUTE, body);
  }

  public updateNovatiqAttributeWithDataOwner(body) {
    return this.http.put(ATTRIB_API_URL.DATAOWNER_ATTRIBUTE, body);
  }

  public createRangeAttribute(body) {
    return this.http.post(API_URL.CUSTOM_RANGED_ATTRIBUTE, body);
  }

  public deleteAttribute(attributeId: string) {
    return this.http.delete(`${API_URL.CUSTOM_RANGED_ATTRIBUTE}/${attributeId}`);
  }

  public deleteManagedAttributes(attributeId) {
    return this.http.delete(`${API_URL.MANAGED_ATTRIBUTES}/${attributeId}`);
  }

  public getAttributeListWithDataPartner(dataOwnerId) {
    let params = new HttpParams();
    params = params.append(ATTRIB_API_URL.DATAOWNERID, dataOwnerId);
    return this.http.get(ATTRIB_API_URL.ATTRIBUTE_LIST, params);
  }

  public getCRMList(dataOwnerId) {
    return this.http.get(`${ATTRIB_API_URL.CRM_LIST}/${dataOwnerId}`);
  }

  public getCountriesByAttributeId(attributeId) {
    let params = new HttpParams();
    params = params.append(ATTRIB_API_URL.ATTRIBUTEID, attributeId);
    return this.http.get(ATTRIB_API_URL.ATTRIBUTE_COUNTRIES, params);
  }

  public getCountriesForPresets(attributeId, name) {
    let params = new HttpParams();
    params = params.append(ATTRIB_API_URL.ATTRIBUTEID, attributeId);
    params = params.append(ATTRIB_API_URL.NAME, name);
    return this.http.get(ATTRIB_API_URL.PRESET_COUNTRIES, params);
  }

  public getCampignAttributes(attributeId, countryCode) {
    let params = new HttpParams();
    params = params.append(ATTRIB_API_URL.ATTRIBUTEID, attributeId);
    params = params.append(ATTRIB_API_URL.COUNTRYISO, countryCode);
    return this.http.get(ATTRIB_API_URL.ATTRIBUTE_CAMPIGNS, params);
  }

  public getAttributeListByCountries(countries) {
    let params = new HttpParams();
    params = params.append(ATTRIB_API_URL.COUNTRIES, countries);
    return this.http.get(ATTRIB_API_URL.ATTRIBUTES, params);
  }

  public getUnAssociatedAttributesByDataOwner(dataOwnerId) {
    let params = new HttpParams();
    params = params.append(ATTRIB_API_URL.DATAOWNERID, dataOwnerId);
    return this.http.get(ATTRIB_API_URL.UNASSOCIATED_NOVATIQ_ATTRIBUTES, params);
  }

  public getAttributeCategories() {
    let params = new HttpParams();
    return this.http.get(ATTRIB_API_URL.ATTRIBUTE_CATEGORY, params);
  }

  public getDataOwner() {
    return this.http.get(ATTRIB_API_URL.DATA_OWNER_MNO);
  }

  public getDataPartner() {
    return this.http.get(ATTRIB_API_URL.DATA_OWNER_DSP);
  }

  public updateSchema(dataOwnerId) {
    return this.http.post(`${ATTRIB_API_URL.SCHEMA_TRANSFORM}?dataOwnerId=${dataOwnerId}`);
  }

  public getPresetByIdAndCountry(attributeId, countryId) {
    return this.http.delete(`${API_URL.MANAGED_ATTRIBUTES}/${attributeId}/${countryId}`);
  }

  public updateRangeAttribute(attributeId, body) {
    return this.http.put(`${API_URL.CUSTOM_RANGED_ATTRIBUTE}/${attributeId}`, body);
  }

  public setAttributeStatus(atrributeName, status, country, attributeId) {
    const body = {
      attributeName: atrributeName,
      status: status,
      country: country,
      attributeId: attributeId
    };
    return this.http.post(API_URL.ACTIVATEACTION, body);
  }

  public updateAttributeDescription(body) {
    return this.http.put(API_URL.NOVATIQATTRIBUTE, body);
  }

  public updateMultiValueAttributesOrder(data: any) {
    return this.http.patch(`${ATTRIB_API_URL.MULTIVALUE_ATTRIBUTES_ORDER}`, data);
  }

  public getUnknownPresetListByCountry(countryISO) {
    return this.http.get(`${API_URL.UNKNOWN_PRESET}/${countryISO}`);
  }

  public onboardUnknownAttribute(body) {
    return this.http.post(API_URL.ONBOARD_UNKNOWN_PRESET, body);
  }
}
