import { NovatiqAuthorizationClient } from '@/_auth-client';
import { mainMenuItems, navigationMenuItems } from '@/_contants';
import { UserRole } from '@/_contants/novatiq-user-role-constants';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapSideBarData {
  constructor(private readonly AuthorizationClient: NovatiqAuthorizationClient) {
    this.initload();
  }

  clone(obj) {
    if (!obj || typeof obj !== 'object') {
      return obj;
    }
    const temp = new obj.constructor();
    Object.keys(obj).forEach((key) => {
      temp[key] = this.clone(obj[key]);
    });
    return temp;
  }

  initload() {
    const userDetails = this.AuthorizationClient.loggedInUser;
    let menuItems = [];
    let functionNames = [];

    if (userDetails?.superAdmin) {
      functionNames = UserRole.SUPERDAMIN;
    } else if (userDetails?.admin) {
      functionNames = this.AuthorizationClient.loggedInUser?.functions
        ? this.AuthorizationClient.loggedInUser.functions
        : [];
      functionNames.push(...UserRole.ADMIN);
    } else {
      functionNames = this.AuthorizationClient?.loggedInUser?.functions;
    }

    const allocatatedMenu = [];
    if (functionNames) {
      navigationMenuItems.forEach((menu) => {
        if (functionNames.indexOf(menu.label) > -1) {
          allocatatedMenu.push(menu);
        }
      });

      menuItems = this.menuBuilder(allocatatedMenu);
    }

    return menuItems.sort(function (a: any, b: any) {
      return a.id - b.id;
    });
  }

  private menuBuilder(allowedfunction) {
    const sideBarMenu = [];

    for (let index = 0; index <= allowedfunction.length - 1; index++) {
      const menuName = allowedfunction[index];
      const subMenuItem = navigationMenuItems.filter((menu) => menu.label === menuName.label)[0];
      const mainMenu = this.clone(mainMenuItems.filter((menu) => menu.id === subMenuItem.parentId)[0]);
      let selMenu;
      for (let i = 0; i < sideBarMenu.length; i++) {
        if (sideBarMenu[i].id === mainMenu.id) {
          selMenu = sideBarMenu[i].id;
        }
      }
      if (!selMenu) {
        sideBarMenu.push(mainMenu);
      }
      selMenu = sideBarMenu.filter((menu) => menu.id === mainMenu.id)[0];
      let selSubMenu;
      if (subMenuItem.isChildMenu) {
        if (selMenu.items) {
          for (let j = 0; j < selMenu.items.length; j++) {
            if (selMenu.id === subMenuItem.id) {
              selSubMenu = selMenu.items[j];
            }
          }
        }
        if (!selSubMenu) {
          selMenu.items.push(subMenuItem);
        }
      }
    }
    return sideBarMenu;
  }
}
