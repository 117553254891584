import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { NovatiqPopupHelper } from './novatiq-popup.helper';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesHelper {
  private readonly subject = new Subject<boolean>();
  constructor(private readonly popupHelper: NovatiqPopupHelper) {}

  checkUnsavedChanges() {
    return this.subject.asObservable();
  }

  cancelNavigation() {
    this.subject.next(false);
  }

  discardChanges() {
    this.subject.next(true);
  }

  navigateAfterSave() {
    this.popupHelper.close();
    this.subject.next(true);
  }

  checkSaveChangesonLogout() {
    this.popupHelper.open('unsavedChanges');
    return this.subject.asObservable().toPromise();
  }
}
