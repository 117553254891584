<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar (menuclicked)="menuClicked($event)"></app-topbar>
  <div class="layout-sidebar">
    <app-sidebar class="app-sidebar"></app-sidebar>
  </div>
  <p-button
    class="sidebar-collapsible"
    [icon]="collapsibleClass"
    [rounded]="true"
    [text]="true"
    [raised]="true"
    styleClass="white-bg collapsible-icon"
    (click)="layoutService.onMenuToggle()"
  ></p-button>
  <div class="layout-main-container">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="layout-mask"></div>
</div>
<p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{ width: '40vw' }">
  <ng-template pTemplate="footer">
    <button
      pButton
      type="button"
      label="{{ 'No' | translate }}"
      icon="pi pi-times"
      class="p-button-text mr-4"
      (click)="cd.reject()"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="{{ 'Yes' | translate }}"
      (click)="cd.accept()"
    ></button> </ng-template
></p-confirmDialog>
<p-toast position="top-center"></p-toast>
