export const APP = {
  STONG_PASSWORD_PATTERN:
    '^(?=.*[0-9])' +
    '(?=.*[a-z])' +
    '(?=.*[A-Z])' +
    '(?=.*[!@#$£%^&*()_+\\-\\[\\]{}|])' +
    '(?=.*[^<>\'"/;`])' +
    '(?=\\S+$).{8,100}$',
  CUSTOM_NUMBER_PATTERN: '^[1-9][0-9]?$|^100$',
  DECMIAL_NUMBER_PATTERN: /^\d{0,5}(\.\d{0,2})?$/
};
