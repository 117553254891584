import { Injectable } from '@angular/core';
import { CreateSSPIDModel } from '@/_types/models';
import { Subject } from 'rxjs';
import { SSPManagementService } from '@/_services';
import { NovatiqAuthorizationClient } from '@/_auth-client';

@Injectable({
  providedIn: 'root'
})
export class CreatesspidHelper {
  inviteUserDataSource = new Subject<CreateSSPIDModel>();

  constructor(
    private readonly userManagementService: SSPManagementService,
    private readonly authorizationClient: NovatiqAuthorizationClient
  ) {}

  checkIfSSPIDExists(existingSSPidList: any = [], sspid: any): boolean {
    let isSSPIDExists: boolean;
    if (existingSSPidList.indexOf(sspid.toLocaleLowerCase().trim()) !== -1) {
      isSSPIDExists = true;
    } else {
      isSSPIDExists = false;
    }
    return isSSPIDExists;
  }
}
