import { CampaignService } from '@/_services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudienceBuilderHelper {
  audienceType: string;
  constructor(private readonly campaignService: CampaignService) {}
  public setAudienceType(type: string) {
    this.audienceType = type;
  }
  public getAudienceType() {
    return this.audienceType;
  }
}
