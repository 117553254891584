export const PopoverPosition = {
  Left: 'left',
  Bottom: 'bottom',
  Right: 'right',
  Top: 'top',
  Auto: 'auto'
};

export const UserType = {
  Novatiq: 'Novatiq',
  DataOwner: 'DataOwner'
};
