import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NovatiqLoadingSpinnerHelper {
  private readonly loading = new BehaviorSubject(false);

  public start() {
    this.loading.next(true);
  }

  public stop() {
    this.loading.next(false);
  }

  public getLoading() {
    return this.loading.asObservable();
  }
}
