import { AUDIENCEBUILDER_API_URL as API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AttributeService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public createRangeAttribute(body) {
    return this.http.post(API_URL.CUSTOM_RANGED_ATTRIBUTE, body);
  }

  public updateRangeAttribute(attributeId, body) {
    return this.http.put(`${API_URL.CUSTOM_RANGED_ATTRIBUTE}/${attributeId}`, body);
  }

  public deleteAttribute(attributeId: string) {
    return this.http.delete(`${API_URL.CUSTOM_RANGED_ATTRIBUTE}/${attributeId}`);
  }
}
