<div>
  <p class="text-center col-12">
    {{ 'hi' | translate }} <label>{{ currentUser?.name }}</label
    >!, <br />
    {{ 'sessionExpiredMessage' | translate }}
  </p>

  <form id="expiryLockLoginForm" [formGroup]="loginForm" #loginFormControl="ngForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="field p-fluid">
      <label htmlFor="username">{{ 'userName' | translate }}</label>
      <input pInputText id="username" type="text" formControlName="username" required disabled="true" />
    </div>
    <div class="field p-fluid">
      <label htmlFor="password">{{ 'password' | translate }}</label>
      <input pInputText id="password" name="password" type="password" formControlName="password" required />
    </div>
    <div class="field col-12">
      <div class="flex flex-column">
        <div class="flex align-items-center justify-content-center mt-2">
          <button
            type="submit"
            pButton
            pRipple
            label="{{ 'login' | translate }}"
            class="w-full text-xl"
            [disabled]="loginForm.invalid"
          ></button>
        </div>
        <div class="flex align-items-center justify-content-center mt-2">
          <a
            class="font-medium no-underline mx-3 cursor-pointer"
            [id]="'ForgotPasswordLink'"
            (click)="forgotPassword()"
            >{{ 'forgotPassword' | translate }}</a
          >
        </div>
      </div>
    </div>
  </form>
</div>
