export const CAMPAIGN_API = {
  ID: 'Id',
  CAMPAIGNS: 'campaign',
  CLIENTS: 'clients',
  COUNTRIES: 'countries',
  LAUNCH_CAMPAIGN: 'campaign',
  CAMPAINGS_STATUS: 'campaignstatus',
  CAMPAINGS_DETAIL: 'campaigndetail',
  CAMPAINGS_DETAILS: 'campaigndetails',
  CAMPAINGS_AUDIENCES: 'campaignsaudience',
  PRICING_FORMAT: 'format'
};
