export enum UserStatus {
  Active = 'Active',
  Pending = 'Pending',
  Inactive = 'Inactive',
  Blocked = 'Blocked',
  Resendactivation = 'Resendactivation'
}

export enum UserAction {
  Edit = 'Edit'
}
