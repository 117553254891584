import { Injectable } from '@angular/core';
import { RoleGroupModel, BaseRoleModel, AddRoleModel } from '@/_types/models';
import { RoleService } from '@/_services';
import { ORGANISATION_TYPE } from '@/_contants';

@Injectable({
  providedIn: 'root'
})
export class RoleHelper {
  organisationTypeOrder: any;
  roleList: RoleGroupModel[];
  roleExists: boolean;

  constructor(private readonly roleService: RoleService) {
    this.initializeProperties();
  }

  checkIfRoleExists(data, organisationType: string, rolename: string) {
    return (
      data.findIndex(
        (role) =>
          role.name.toUpperCase().trim() === rolename.toUpperCase().trim() &&
          role.organisationType.toUpperCase() === organisationType.toUpperCase()
      ) > -1
    );
  }

  deleteRoles(roleId) {
    return this.roleService.deleteRoles(roleId);
  }
  getRoles() {
    return this.roleService.getRoles();
  }

  initializeProperties() {
    this.roleExists = false;
  }

  map(data: any) {
    const mapper = new Set(data.map((item) => item.organisationType));
    this.organisationTypeOrder = [
      ORGANISATION_TYPE.NOVATIQ,
      ORGANISATION_TYPE.DATA_OWNER,
      ORGANISATION_TYPE.DATA_PARTNER
    ];
    this.roleList = [];
    mapper.forEach((g) =>
      this.roleList.push({
        organisationType: g,
        role: data.filter((i) => i.organisationType === g)
      })
    );
    this.roleList.sort(
      (a, b) =>
        this.organisationTypeOrder.indexOf(a.organisationType) - this.organisationTypeOrder.indexOf(b.organisationType)
    );

    return this.roleList;
  }

  saveRoles(role: AddRoleModel) {
    return this.roleService.saveRoles(role);
  }
  getRoleFunction(navigationMenuItems: any) {
    return navigationMenuItems.filter((x) => x.roleId).sort((a: any, b: any) => a.roleId - b.roleId);
  }
  updateRole(id: number, role: BaseRoleModel) {
    return this.roleService.updateRole(id, role);
  }
}
