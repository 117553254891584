import { AUTHORIZATION_API_URL as API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public forgottenPassword(data) {
    return this.http.post(API_URL.CREATE_NEW_PASSWORD, data);
  }

  public getRoles() {
    return this.http.get(API_URL.GET_ROLES);
  }

  public logIn(data: any) {
    return this.http.post(API_URL.LOGIN, data);
  }

  public logOut() {
    return this.http.post(API_URL.LOGOUT, {});
  }

  public refreshToken() {
    return this.http.post(API_URL.REFRESH_TOKEN, {});
  }

  public resetPassword(data) {
    return this.http.post(API_URL.RESET_PASSWORD, data);
  }

  public verifyToken(token: string) {
    return this.http.post(API_URL.VERIFY_TOKEN, { token: token });
  }

  public setup2fa() {
    return this.http.getFile(API_URL.SETUP_2FA, { responseType: 'blob' });
  }

  public verify2FA(token: string) {
    return this.http.post(API_URL.VERIFY_2FA, { code: token });
  }
}
