import { AlertSubject, AlertType } from '@/_types';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NovatiqAlertHelper {
  private readonly subject = new Subject<AlertSubject>();

  clearMessage() {
    this.subject.next(null);
  }

  error(message: string) {
    this.setMessage(AlertType.Error, message);
  }

  getMessage(): Observable<AlertSubject> {
    return this.subject.asObservable();
  }

  info(message: string) {
    this.setMessage(AlertType.Info, message);
  }

  success(message: string) {
    this.setMessage(AlertType.Success, message);
  }

  warning(message: string) {
    this.setMessage(AlertType.Warning, message);
  }

  private setMessage(type: AlertType, message: string) {
    this.subject.next({ type, text: message });
  }
}
