import { AUTHORIZATION_CLIENT } from '@/_contants';
import { CommonAPIService } from '@/_services';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NovatiqGeographyHelper {
  private $geographies: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private $currentGeography: BehaviorSubject<string>;
  private geographiesLoaded: boolean = false;

  constructor(private readonly commonAPIService: CommonAPIService) {
    this.$currentGeography = new BehaviorSubject<string>(this._getGeography());
  }

  clearGeography() {
    this.$currentGeography.next(null);
  }

  private _getGeography(): string {
    return JSON.parse(localStorage.getItem(AUTHORIZATION_CLIENT.GEOGRAPHY));
  }

  getGeography() {
    // clause : check that localstorage.geo is a part of geographies list from api
    // otherwise the user doesn't have access to it and its a cached one so clear it.
    let currentGeo = JSON.parse(localStorage.getItem(AUTHORIZATION_CLIENT.GEOGRAPHY));
    const geographies = this.$geographies.getValue();
    if (geographies && geographies.length > 0) {
      if (this.areAllObjectsPresentById(currentGeo, geographies)) {
        // user has access.
      } else {
        this.clearGeography();
      }
    } else {
      this.clearGeography();
    }
    return this.$currentGeography.asObservable();
  }
  /**
   * check if all objects from arr1 are present in arr2 by property id.
   * @param arr1
   * @param arr2
   * @returns boolean
   */
  areAllObjectsPresentById(arr1, arr2): boolean {
    // Create a Set of IDs from the second array for fast lookup
    const ids = new Set(arr2.map((obj) => obj.id));
    // Check if every object in the first array has an ID present in the Set
    return arr1.every((obj) => ids.has(obj.id));
  }

  setGeography(countries: any) {
    this.$currentGeography.next(countries);
    localStorage.setItem(AUTHORIZATION_CLIENT.GEOGRAPHY, JSON.stringify(countries));
  }

  setGeographies(geographies: any) {
    this.$geographies.next(geographies);
    this.geographiesLoaded = true;
  }

  getGeographies(): Observable<any[]> {
    if (this.geographiesLoaded) {
      return this.$geographies.asObservable();
    } else {
      return this.commonAPIService.getGeographies().pipe(
        map((data: any) => data.countriesEnabled || []),
        tap((geographies: any) => {
          this.setGeographies(geographies);
        }),
        catchError((error) => {
          console.error('Getting and Setting Geographies failed:', error);
          throw new Error('Getting and Setting Geographies failed.');
        })
      );
    }
  }

  clearGeographies() {
    this.$geographies.next(null);
    this.geographiesLoaded = false;
  }
}
