import { Attributes } from '@/_types/models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchAttributesHelper {
  count: number;
  keys: any;
  temp: Attributes[];

  public searchAttributes(rows: Attributes[], val: any) {
    const value = val.toString().toLowerCase().trim();
    this.keys = rows[0] ? Object.keys(rows[0]).filter((k) => k === 'attributeName' || k === 'description') : [];
    this.count = this.keys.length;
    this.temp = rows;
    return this.temp.filter((item) => {
      for (let i = 0; i < this.count; i++) {
        if ((item[this.keys[i]] && item[this.keys[i]].toString().toLowerCase().indexOf(value) !== -1) || !value) {
          return true;
        }
      }
    });
  }
}
