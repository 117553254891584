@if (showPopup) {
  <p-dialog
    header="Header"
    [(visible)]="showPopup"
    [modal]="hideBackground"
    [styleClass]="modalsize"
    appendTo="body"
    (onHide)="close()"
    [closable]="defaultClose"
    [focusOnShow]="false"
  >
    <ng-template pTemplate="header">
      <div
        class="inline-flex align-items-center justify-content-center gap-2"
        [class]="{
          redthemepopup: theme === 'red',
          orangethemepopup: theme === 'orange'
        }"
      >
        <span class="font-bold white-space-nowrap text-lg" translate>{{ title }} </span>
      </div>
    </ng-template>
    <ng-content select="[slot=popup-body]"></ng-content>

    @if (showFooter) {
      <ng-template pTemplate="footer">
        <ng-content select="[slot=popup-footer]"></ng-content>
      </ng-template>
    }
  </p-dialog>
}
