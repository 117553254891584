import { Injectable } from '@angular/core';
import { UserManagementService } from '@/_services';

@Injectable({
  providedIn: 'root'
})
export class NovatiqEditUserHelper {
  constructor(private readonly userManagementService: UserManagementService) {}

  updateUser(selectedUserId: any, editUserData: any) {
    return this.userManagementService.updateUser(selectedUserId, editUserData);
  }
}
