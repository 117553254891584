export const DISCOUNT_TYPE_CONSTANT = {
  APPLY_FOR_DATAPARTNER: 'applyForDataPartner',
  REJECT_FOR_DATAPARTNER: 'rejectForDataPartner',
  APPLY_FOR_CLIENT: 'applyForClient',
  REJECT_FOR_CLIENT: 'rejectForClient',
  REQUEST_FOR_DATAPARTNER: 'requestForDataPartner',
  REQUEST_FOR_CLIENT: 'requestForClient',
  ACCEPT_FOR_DATAPARTNER: 'acceptForDataPartner',
  ACCEPT_FOR_CLIENT: 'acceptForClient'
};
