import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoryManagerHelper {
  count: number;
  keys: any;

  searchCategory(categories: any, val: any) {
    const value = val.toString().toLowerCase().trim();
    this.keys = Object.keys(categories[0]);
    this.count = this.keys.length;
    return categories.filter((item) => {
      if (item.category.toString().toLowerCase().indexOf(value) !== -1 || !value) {
        return true;
      }
    });
  }
}
