import { Injectable } from '@angular/core';
import { NovatiqHttpClient } from '@/_http-client';
import { ADMINISTRATION_API_URL as API_URL } from '@/_contants';

@Injectable({
  providedIn: 'root'
})
export class CategoryManagerService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public updateCategory(id: any, data: any) {
    return this.http.put(`${API_URL.CATEGORIES}/${id}`, data);
  }
  public deleteCategory(id: any) {
    return this.http.delete(`${API_URL.CATEGORIES}/${id}`);
  }
  public addCategory(category: any) {
    return this.http.post(API_URL.CATEGORIES, category);
  }
}
