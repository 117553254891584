export const ORGANISATION_TYPE = {
  DEFAULT_DATA_OWNER: 'Default Data Owner',
  DATA_OWNER_NAME: 'dataOwnerName',
  DATA_OWNER: 'DataOwner',
  DATA_PARTNER: 'BuySide',
  NOVATIQ: 'Novatiq',
  DATA_OWNER_ID: 'dataOwnerId',
  AGENCY: 'Agency',
  PUBLISHER: 'Publisher'
};
