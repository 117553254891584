import { CAMPAIGN_API as API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonAPIService } from './novatiq-commonapi.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  constructor(
    private readonly http: NovatiqHttpClient,
    private readonly commonAPIService: CommonAPIService
  ) {}

  public getGeography() {
    return this.commonAPIService.getGeographies();
  }

  public getAllCategories() {
    return this.commonAPIService.getAllCategories();
  }

  public getClientByDataPartner(countries) {
    let params = new HttpParams();
    params = params.append(API_URL.COUNTRIES, countries);
    return this.commonAPIService.getClientByDataPartner(params);
  }

  createCampaign(body) {
    return this.http.post(API_URL.CAMPAIGNS, body);
  }

  updateCampaign(body) {
    return this.http.put(API_URL.CAMPAINGS_AUDIENCES, body);
  }

  updateCampaignStatus(body) {
    return this.http.post(API_URL.CAMPAINGS_STATUS, body);
  }

  addAudienceToCampaign(body) {
    return this.http.post(API_URL.CAMPAINGS_AUDIENCES, body);
  }

  launchCampaign(body) {
    return this.http.put(API_URL.LAUNCH_CAMPAIGN, body);
  }

  getAllCampaignByCountry(countries, pricingFormat) {
    let params = new HttpParams();
    params = params.append(API_URL.COUNTRIES, countries);
    params = params.append(API_URL.PRICING_FORMAT, pricingFormat);
    return this.http.get(API_URL.CAMPAIGNS, params);
  }

  getDashboardCampaignByCountry(countries) {
    let params = new HttpParams();
    params = params.append(API_URL.COUNTRIES, countries);
    return this.http.get(API_URL.CAMPAIGNS, params);
  }

  getCampaignForDashboard(countries = '') {
    let params = new HttpParams();
    params = params.append(API_URL.COUNTRIES, countries);
    return this.http.get(API_URL.CAMPAINGS_DETAILS, params);
  }

  getCampaignById(id) {
    let params = new HttpParams();
    params = params.append(API_URL.ID, id);
    return this.http.get(API_URL.CAMPAINGS_DETAIL, params);
  }
}
