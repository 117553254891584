export enum HTTP_ERROR_CODE {
  INVALID_USERNAME_OR_PASSWORD = '400A',
  RESET_TOKEN_EXPIRED = '400-R',
  USER_VERIFICTION_TOKEN_EXPIRED = '400-V',
  AUTHENTICATION_TOKEN_EXPIRED = '401-T',
  INTERNAL_SERVER_ERROR = 500,
  PASSWORD_EXPIRED = 1000008,
  AUDIENCE_EXISTS = '5004029',
  BUYSIDE_AND_MAPPING_EXISTS = 5003007,
  BESPOKE_ATTR_IN_USE = 'bespoke-attribute-removal'
}
