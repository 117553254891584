import { ADMINISTRATION_API_URL as API_URL, ORGANISATION_TYPE } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoryBlocklistsService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public getCategoryBlocklist(dataOwnerId: string) {
    let params = new HttpParams();
    params = params.append(ORGANISATION_TYPE.DATA_OWNER_ID, dataOwnerId);
    return this.http.get(API_URL.JAVA_V2 + API_URL.CATEGORY_BLOCKLIST, params);
  }
  public getCategories() {
    return this.http.get(API_URL.CATEGORIES);
  }
  public getMNO(internal?: boolean) {
    let MNO_API_URL = API_URL.DATA_OWNER_MNO;
    if (internal) {
      MNO_API_URL = MNO_API_URL + '?internal=' + internal;
    }
    return this.http.get(MNO_API_URL);
  }

  public saveCategories(dataOwnername: string, data: any) {
    const varName = {
      campaignCategories: []
    };
    for (let i = 0; i < data.campaignCategory.length; i++) {
      varName.campaignCategories.push(data.campaignCategory[i].category);
    }
    return this.http.post(API_URL.CATEGORY_BLOCKLIST + '/' + dataOwnername, varName);
  }
}
