import { Injectable } from '@angular/core';
import { UtilHelper } from '../novatiq-util-helper';

@Injectable({
  providedIn: 'root'
})
export class AttributeHelper {
  attribType: string;
  constructor(private readonly utilHelper: UtilHelper) {}

  resetDefault(editValue) {
    editValue.id = null;
    editValue.name = '';
    editValue.countryIsos = [];
    editValue.value = '';
    editValue.maxValue = '';
    editValue.criteria = 'EQUAL';
    return editValue;
  }

  resetAttributes(editValue) {
    editValue.id = null;
    editValue.attributeName = '';
    editValue.attributeDesc = '';
    editValue.dataType = '';
    editValue.crm = '';
    return editValue;
  }

  public setAttribType(type: string) {
    this.attribType = type;
  }
  public getAttribType() {
    return this.attribType;
  }
}
