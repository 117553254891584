import { Injectable } from '@angular/core';
import { NovatiqHttpClient } from '@/_http-client';
import { AUTHORIZATION_API_URL as API_URL } from '@/_contants';
import { BehaviorSubject } from 'rxjs';
import { SSPModel } from '@/_types';

@Injectable({
  providedIn: 'root'
})
export class SSPManagementService {
  private readonly UserList: BehaviorSubject<SSPModel[]>;

  constructor(private readonly http: NovatiqHttpClient) {}
  public getUserList() {
    return this.UserList.asObservable();
  }
  public getSSPid() {
    return this.http.get(API_URL.GET_SSPIDS);
  }
  public updateSSPid(userId: any = {}, data: any) {
    return this.http.put(`${API_URL.GET_SSPIDS}/${userId}`, data);
  }
  public deleteSSPId(sspid: any) {
    return this.http.delete(`${API_URL.GET_SSPIDS}/${sspid}`);
  }
  public postUser(data: any) {
    return this.http.post(API_URL.GET_SSPIDS, data);
  }
}
