import { ROUTES } from '@/_contants';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationLayoutComponent, ErrorLayoutComponent } from './layouts';
import { AppLayoutComponent } from './layouts/app.layout.component';
import { AuthenticationGuard } from './_guards';
import { RoutePathMatch } from './_types';

export const routes: Routes = [
  {
    path: ROUTES.EMPTY_URL,
    redirectTo: ROUTES.LOGIN.path,
    pathMatch: RoutePathMatch.Full
  },
  {
    path: ROUTES.ADMINISTRATION.path,
    component: AppLayoutComponent,
    children: [
      {
        path: ROUTES.EMPTY_URL,
        loadChildren: () =>
          import('@/features/administration/administration.module').then((m) => m.AdministrationModule)
      }
    ],
    data: {
      title: ROUTES.ADMINISTRATION.name,
      isAdminRoutes: true
    },
    canActivateChild: [AuthenticationGuard]
  },

  {
    path: ROUTES.EMPTY_URL,
    component: AppLayoutComponent,
    children: [
      {
        path: ROUTES.EMPTY_URL,
        loadChildren: () =>
          import('@/features/creator/audience-builder/audience-builder.module').then((m) => m.AudienceBuilderModule)
      }
    ],
    data: {
      isAdminRoutes: false
    },
    canActivateChild: [AuthenticationGuard]
  },
  {
    path: ROUTES.CREATOR.path,
    component: AppLayoutComponent,
    children: [
      {
        path: ROUTES.EMPTY_URL,
        loadChildren: () => import('@/features/creator/creator.module').then((m) => m.CreatORModule)
      }
    ],
    data: {
      title: ROUTES.CREATOR.name,
      isAdminRoutes: false
    },
    canActivateChild: [AuthenticationGuard]
  },

  {
    path: ROUTES.EMPTY_URL,
    component: AppLayoutComponent,
    children: [
      {
        path: ROUTES.EMPTY_URL,
        loadChildren: () => import('@/features/campign-builder.module').then((m) => m.CampignBuilderModule)
      }
    ],
    data: {
      isAdminRoutes: false
    },
    canActivateChild: [AuthenticationGuard]
  },

  {
    path: ROUTES.EMPTY_URL,
    component: AppLayoutComponent,
    children: [
      {
        path: ROUTES.EMPTY_URL,
        loadChildren: () =>
          import('@/features/campign-dashboard/campign-dashboard.module').then((m) => m.CampignDashboardModule)
      }
    ],
    data: {
      isAdminRoutes: false
    },
    canActivateChild: [AuthenticationGuard]
  },
  {
    path: ROUTES.REPORTING.path,
    component: AppLayoutComponent,
    children: [
      {
        path: ROUTES.EMPTY_URL,
        loadChildren: () => import('@/features/reporting/reporting.module').then((m) => m.ReportingModule)
      }
    ],
    data: {
      title: ROUTES.REPORTING.name,
      isAdminRoutes: false
    },
    canActivateChild: [AuthenticationGuard]
  },
  {
    path: ROUTES.EMPTY_URL,
    component: AuthorizationLayoutComponent,
    children: [
      {
        path: ROUTES.EMPTY_URL,
        loadChildren: () => import('@/features/authorization/authorization.module').then((m) => m.AuthorizationModule)
      }
    ],
    data: {
      isAnonymous: true
    },
    canActivateChild: [AuthenticationGuard]
  },
  {
    path: ROUTES.EMPTY_URL,
    component: ErrorLayoutComponent,
    children: [
      {
        path: ROUTES.EMPTY_URL,
        loadChildren: () => import('@/features/error/error.module').then((m) => m.ErrorModule)
      }
    ]
  },
  {
    path: ROUTES.ZENITH_BILLING.path,
    component: AppLayoutComponent,
    children: [
      {
        path: ROUTES.EMPTY_URL,
        loadChildren: () => import('@/features/zenith-billing/zenith-billing.module').then((m) => m.ZenithBillingModule)
      }
    ],
    data: {
      isAdminRoutes: false
    },
    canActivateChild: [AuthenticationGuard]
  },
  {
    path: ROUTES.OTHERS,
    redirectTo: ROUTES.PAGE_404.path,
    pathMatch: RoutePathMatch.Full
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
