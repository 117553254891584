import { AUDIENCEBUILDER_API_URL as API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { ISelections } from '@/_types';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudienceBuilderService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public getAllAttributes(country, all: boolean) {
    return this.getAttributes(API_URL.ATTRIBUTES, country, all);
  }

  public getLocationAttribute(countries) {
    let params = new HttpParams();

    if (countries) {
      params = params.append('countries', countries);
      return this.http.get(`${API_URL.LOCATION_ATTRIBUTE}`, params);
    } else {
      return this.http.get(API_URL.LOCATION_ATTRIBUTE);
    }
  }

  public getMultiValuePresets(body) {
    return this.http.get(API_URL.MULTIVALUES, body);
  }

  public markAsCustomLocation(body) {
    return this.http.post(API_URL.LOCATION_ATTRIBUTE, body);
  }

  public removeCustomLocation(body) {
    return this.http.deleteWithParam(API_URL.LOCATION_ATTRIBUTE, body);
  }

  private getAttributes(apiURL, country, all: boolean) {
    let params = new HttpParams();

    if (all) {
      params = params.append('all', all);
    }
    return this.http.get(`${apiURL}/${country}`, params);
  }

  public getAllAudience(countries, type, isStandard) {
    let params = new HttpParams();
    params = params.append(API_URL.COUNTRIES, countries);
    params = params.append(API_URL.FORMAT, type);
    params = params.append(API_URL.ISSTANDARD, isStandard);
    return this.http.get(API_URL.AUDIENCE_DETAILS, params);
  }

  public getAudienceById(id) {
    let params = new HttpParams();
    params = params.append(API_URL.ID, id);
    return this.http.get(API_URL.AUDIENCES, params);
  }

  public saveAudience(body) {
    return this.http.post(API_URL.AUDIENCES, body);
  }

  public updateAudience(audienceId, body) {
    return this.http.put(`${API_URL.AUDIENCES}/${audienceId}`, body);
  }

  public updateAudiencePriceOfLiveCampaign(countries, campaignId, audienceId) {
    return this.http.put(
      `${API_URL.AUDIENCE_PRICE}?countries=${countries}&campaignId=${campaignId}&audienceId=${audienceId}`
    );
  }

  public listExpressions(attributeName) {
    let params = new HttpParams();
    params = params.append(API_URL.ATTRIBUTENAME, attributeName);
    return this.http.get(API_URL.AUDIENCE_EXPRESSION, params);
  }

  public deleteAudience(audienceId) {
    return this.http.delete(`${API_URL.AUDIENCES}/${audienceId}`);
  }

  public removePresets(presetId: any, country: any) {
    return this.http.deletePreset(API_URL.MANAGED_ATTRIBUTES, presetId, country);
  }

  public updatePreset(body) {
    return this.http.post(API_URL.MANAGED_ATTRIBUTES, body);
  }

  public editPreset(body) {
    return this.http.put(API_URL.MANAGED_ATTRIBUTES, body);
  }

  validateAudienceFormation(countryCode: string, body: ISelections): Observable<any> {
    if (body.selections.length == 1) {
      console.log('Selections array must have at least 2 elements');
      return of({ value: true });
    } else if (body.selections.length < 1) {
      console.log('Selections array must have at least 1 elements');
      return of(null);
    }
    return this.http.post(`audience/${countryCode}/validate`, body);
  }
}
