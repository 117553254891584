import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastorHelper {
  constructor(private readonly toastor: ToastrService) {}

  successMessage(message: string, title?: string) {
    return this.toastor.success(message, title);
  }

  infoMessage(message: string, title?: string) {
    return this.toastor.info(message, title);
  }

  warningMessage(message: string, title?: string) {
    return this.toastor.warning(message, title);
  }

  errorMessage(message: string, title?: string) {
    return this.toastor.error(message, title);
  }
}
