import { ADMINISTRATION_API_URL as API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonAPIService } from './novatiq-commonapi.service';

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  constructor(
    private readonly http: NovatiqHttpClient,
    private readonly commonAPIService: CommonAPIService
  ) {}

  public getGeography() {
    return this.commonAPIService.getGeographies();
  }

  public getPricingAttributes(countryId: string) {
    let params = new HttpParams();
    params = params.append('countries', countryId);
    return this.http.get(API_URL.GET_ATTRIBUTE_PRICING, params);
  }

  public getPricingAttributesDiscount(countryId: string) {
    let params = new HttpParams();
    params = params.append('countryIso', countryId);
    return this.http.get(API_URL.GET_ATTRIBUTE_DISCOUNT, params);
  }

  public updatePricing(data: any) {
    return this.http.post(API_URL.POST_PRICING, data);
  }
  public updatePricingDiscount(data: any) {
    return this.http.put(API_URL.GET_ATTRIBUTE_DISCOUNT, data);
  }

  public getCurrencies() {
    return this.http.get(API_URL.CURRENCIES);
  }
}
