import { NovatiqAuthorizationClient } from '@/_auth-client';
import { exceptionalNavMenuItems, navigationMenuItems, ROUTES, topRightSettingsMenu } from '@/_contants';
import { UserRole } from '@/_contants/novatiq-user-role-constants';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly AuthorizationClient: NovatiqAuthorizationClient,
    private readonly router: Router
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this._checkPermissions(activatedRouteSnapshot.data, state);
  }

  canActivateChild(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this._checkPermissions(activatedRouteSnapshot.data, state);
  }

  private _checkPermissions(data, state): boolean {
    if (data.isAnonymous) {
      if (state.url === ROUTES.SETUP_2FA.url || state.url === ROUTES.VERIFY_2FA.url) {
        return true;
      } else if (!this.AuthorizationClient.isAuthenticated || this.AuthorizationClient.sessionExpired) {
        this.AuthorizationClient.removeUserSession();
        return true;
      } else {
        this.router.navigate([this.AuthorizationClient.landingPage]);
        return false;
      }
    } else if (this.AuthorizationClient.isAuthenticated) {
      if (this.AuthorizationClient.sessionExpired) {
        return this.navigateTo(ROUTES.LOGIN.path);
      } else if (data.isAdminRoutes) {
        const currentRouteName =
          navigationMenuItems.filter((item) => item?.routerLink?.includes(state.url))[0]?.label ||
          topRightSettingsMenu.filter((item) => item?.routerLink?.includes(state.url))[0]?.label;

        const exp = exceptionalNavMenuItems.find((navmenu) => state.url.includes(navmenu.routerLink[0]));
        if (
          this.AuthorizationClient.loggedInUser?.functions?.includes(currentRouteName) ||
          this.AuthorizationClient.loggedInUser?.superAdmin
        ) {
          return true;
        } else if (this.AuthorizationClient.loggedInUser?.admin && UserRole.ADMIN.includes(currentRouteName)) {
          return true;
        } else if (exp) {
          return true;
        } else {
          this.navigateTo(ROUTES.PAGE_401.path);
        }
      }

      return true;
    }

    return this.navigateTo(ROUTES.LOGIN.path);
  }

  private navigateTo(destination: string) {
    this.router.navigate([destination]);
    return false;
  }
}
