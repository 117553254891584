import { Injectable } from '@angular/core';
import { GlossaryModel } from '@/_types/models';

@Injectable({
  providedIn: 'root'
})
export class NovatiqSupportHelper {
  count: number;
  glossaryList: GlossaryModel[];
  keys: any;
  temp: GlossaryModel[];

  public searchGlossary(rows: GlossaryModel[], val: any) {
    const value = val.toString().toLowerCase().trim();
    this.keys = Object.keys(rows[0]);
    this.count = this.keys.length;
    this.temp = rows;
    return this.temp.filter((item) => {
      for (let i = 0; i < this.count; i++) {
        if ((item[this.keys[i]] && item[this.keys[i]].toString().toLowerCase().indexOf(value) !== -1) || !value) {
          return true;
        }
      }
    });
  }
}
