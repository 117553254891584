import { ROUTES } from '@/_contants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NovatiqHttpClient {
  private readonly apiUrl = environment.NovatiqAPIURL;

  constructor(private readonly http: HttpClient) {}

  delete(url: string): Observable<any> {
    return this.http.delete(this.apiUrl + url);
  }

  deleteWithBody(url: string, body: any = {}): Observable<any> {
    return this.http.delete(this.apiUrl + url, { body });
  }

  deleteWithParam(url: string, params: any): Observable<any> {
    return this.http.delete(this.apiUrl + url, { params });
  }

  deletePreset(url: string, id: any, country: any): Observable<any> {
    return this.http.delete(`${this.apiUrl + url + '/' + id + '/' + country}`);
  }

  get(url: string, params?: any): Observable<any> {
    return this.http.get(this.apiUrl + url, { params });
  }

  getFile(url: string, responseType: any): Observable<any> {
    return this.http.get(this.apiUrl + url, responseType);
  }

  getLocal(url: string): Observable<any> {
    return this.http.get(ROUTES.BASE_URL + url);
  }

  post(url: string, data: any = {}): Observable<any> {
    return this.http.post(this.apiUrl + url, data);
  }

  put(url: string, data: any = {}): Observable<any> {
    return this.http.put(this.apiUrl + url, data);
  }

  patch(url: string, data: any = {}): Observable<any> {
    return this.http.patch(this.apiUrl + url, data);
  }
}
