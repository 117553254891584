import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NovatiqPopoverHelper {
  private readonly showPopover = new BehaviorSubject<string>(null);

  close() {
    this.showPopover.next(null);
  }

  getPopover() {
    return this.showPopover.asObservable();
  }

  open(id: string) {
    this.showPopover.next(id);
  }

  toggle(id: string) {
    if (this.showPopover.value === id) {
      this.close();
    } else {
      this.open(id);
    }
  }
}
