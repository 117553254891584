import { Injectable } from '@angular/core';
import { InviteUserModel } from '@/_types/models';
import { Subject } from 'rxjs';
import { UserManagementService } from '@/_services';
import { NovatiqAuthorizationClient } from '@/_auth-client';

@Injectable({
  providedIn: 'root'
})
export class InviteUserHelper {
  inviteUserDataSource = new Subject<InviteUserModel>();

  constructor(
    private readonly userManagementService: UserManagementService,
    private readonly authorizationClient: NovatiqAuthorizationClient
  ) {}

  checkIfUserNameExists(existingEmailsList: any = [], userName: any): boolean {
    let isUserNameExists: boolean;
    if (existingEmailsList.indexOf(userName.toLocaleLowerCase()) !== -1) {
      isUserNameExists = true;
    } else {
      isUserNameExists = false;
    }
    return isUserNameExists;
  }

  saveUser(user: InviteUserModel) {
    return this.userManagementService.postUser(user);
  }

  getOrganisationsList(loggedInUserOrganisationType: string) {
    return this.userManagementService.getOrganisations(loggedInUserOrganisationType);
  }
}
