export enum INTEGRATIONTYPE {
  'supply_only' = 'Supply Only',
  'supply_and_demand' = 'Supply and Demand'
}

export enum SSPIDSTATUS {
  'active' = 'Active',
  'inactive' = 'Inactive',
  'pending_integration' = 'Pending Integration',
  'in_progress' = 'In Progress'
}

export enum ORGANISATIONTYPE {
  'ssp' = 'SSP',
  'publisher' = 'Publisher',
  'exchange' = 'Exchange',
  'other' = 'Other'
}
