import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppStorage } from '@/_storage';
import { NovatiqPopupHelper, UnsavedChangesHelper } from '@/_helpers';

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard implements CanDeactivate<any> {
  constructor(
    private readonly dataStorage: AppStorage,
    private readonly popupService: NovatiqPopupHelper,
    private readonly unsavedChangesHelper: UnsavedChangesHelper
  ) {}

  canDeactivate() {
    if (this.dataStorage.ContainsKey('EditMode')) {
      this.popupService.open('unsavedChanges');
      return this.unsavedChangesHelper.checkUnsavedChanges();
    } else {
      return true;
    }
  }
}
