export const ATTRIBUTESONBOARDANDMANAGE_API_URL = {
  COUNTRIES: 'countries',
  ATTRIBUTES: 'attributes',
  COUNTRYISO: 'countryIso',
  ATTRIBUTEID: 'attributeId',
  DATAOWNERID: 'dataownerID',
  TYPE: 'type',
  DATAOWNER_ID: 'dataOwnerId',
  DATAPROVIDER: 'dataProvider',
  ATTRIBUTE_LIST: 'attributelist',
  ATTRIBUTE_COUNTRIES: 'attribute-countries',
  CRM_LIST: 'writer-schema',
  DATA_OWNER_MNO: 'data-owners/mno',
  NOVATIQ_ATTRIBUTES: 'novatiqAttribute',
  UNASSOCIATED_NOVATIQ_ATTRIBUTES: 'unAssociatedNovatiqAttributes',
  DATAOWNER_ATTRIBUTE: 'dataOwnerAttribute',
  DATA_OWNER_DSP: 'data-owners/dsp',
  NAME: 'name',
  PRESET_COUNTRIES: 'preset-countries',
  ATTRIBUTE_CAMPIGNS: 'attribute-campaigns',
  ACTIVE_CAMPAIGNLIST: 'attribute-campaigns ',
  SCHEMA_TRANSFORM: 'schema/transform',
  ATTRIBUTE_CATEGORY: 'attribute-category',
  MULTIVALUE_ATTRIBUTES_ORDER: 'multiValueAttributes/order',
  ATTRIBUTE_ONBOARD: 'attribute_onboarding',
  ACTIVE_CURRENCIES: 'active-currencies',
  BESPOKE_ATTRIBUTES: 'v1/bespoke-attributes',
  SCHEMA: 'schema', //  /schema/{type}/{mno}
  SCHEMAV1: 'v1/schema' //  /v1/schema/{type}/{mno}
};
