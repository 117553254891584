import { NovatiqLoadingSpinnerHelper } from '@/_helpers';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'novatiq-spinner',
  templateUrl: './novatiq-loading-spinner.component.html'
})
export class NovatiqLoadingSpinnerComponent implements OnInit, OnDestroy {
  isLoading: boolean;

  private loadingSubscription: Subscription;

  constructor(
    private readonly loadingSpinnerHelper: NovatiqLoadingSpinnerHelper,
    private readonly ngxSpinner: NgxSpinnerService
  ) {
    this.ngxSpinner.show();
  }
  ngOnInit() {
    this.initialize();
  }

  private initialize() {
    this.loadingSubscription = this.loadingSpinnerHelper.getLoading().subscribe((data) => {
      this.isLoading = data;
    });
  }

  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
  }
}
